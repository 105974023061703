<template>
  <div>
    <div class="deals container">
      <div class="topHead">
        <navigation />
        <div class="topHead__left">
          <ul class="mainLinks">
            <li>
              <a href="javascript:void(0);" v-b-modal.createOffer
                >Create Free Offer</a
              >
            </li>
          </ul>
          <div class="filterWrapper">
            <base-locations v-model="location"></base-locations>
            <base-states-filter v-model="state"></base-states-filter>
          </div>
        </div>
      </div>
      <div v-if="$apollo.loading && this.isFirst">
        <base-skeleton-loader
          type="influencer"
          :count="this.limit"
          v-if="$apollo.queries.myOffers.loading && isFirst"
        ></base-skeleton-loader>
      </div>
      <div class="row" v-else>
        <div
          class="col-xl-3 col-lg-4 col-sm-6"
          v-for="offer in myOffers"
          :key="offer.index"
        >
          <deal-card :offer="offer" />
        </div>
        <infinite-loading
          @infinite="infiniteHandler"
          :identifier="infiniteId"
          :distance="30"
          v-if="!isFirst"
        >
        </infinite-loading>
      </div>
      <div class="no-record" v-if="myOffers.length == 0 && !$apollo.loading">
        No Record Found
      </div>
    </div>
    <create-offer />
  </div>
</template>

<script>
import { MY_OFFERS } from "@/graphql/brand/query";
export default {
  components: {
    CreateOffer: () =>
      import(
        /* webpackChunkName: "CreateCampaign" */ "@/components/brand/deals/CreateOffer.vue"
      ),
    DealCard: () =>
      import(
        /* webpackChunkName: "dealCard" */ "@/components/brand/deals/DealCard.vue"
      ),
    navigation: () =>
      import(
        /* webpackChunkName: "navigations" */ "@/components/brand/Navigation.vue"
      ),
  },
  data() {
    return {
      myOffers: [],
      isFirst: true,
      location: null,
      location_id: null,
      page: 1,
      limit: 12,
      state: null,
      state_id: null,
      infiniteId: +new Date(),
      hasMore: true,
    };
  },
  apollo: {
    myOffers: {
      query: MY_OFFERS,
      variables() {
        return {
          page: this.page,
          limit: this.limit,
          state: this.state_id,
        };
      },
      result(data) {
        if (data.data) {
          if (data.data.myOffers.length == 0) {
            this.hasMore = false;
          }
        }
      },
    },
  },
  methods: {
    infiniteHandler($state) {
      if (this.hasMore) {
        setTimeout(() => {
          try {
            this.$apollo.queries.myOffers.fetchMore({
              variables: {
                page: this.page + 1,
                limit: this.limit,
                state: this.state_id,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const newOffers = fetchMoreResult.myOffers;
                if (newOffers.length) {
                  $state.loaded();
                } else {
                  $state.complete();
                  this.hasMore = false;
                }
                if (newOffers.length < this.limit) {
                  this.hasMore = false;
                }
                return {
                  myOffers: [...previousResult.myOffers, ...newOffers],
                };
              },
            });
          } catch (err) {
            $state.error();
          }
        }, 500);
      } else {
        $state.complete();
      }
    },
  },
  watch: {
    myOffers() {
      this.isFirst = false;
    },
    location(data) {
      this.location_id = data.id;
      this.hasMore = true;
      this.infiniteId += 1;
      this.page = 1;
    },
    state(state) {
      this.state_id = state.id;
      this.hasMore = true;
      this.infiniteId += 1;
      this.page = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.deals {
  ::v-deep {
    .filters {
      padding-top: 0;
      @media screen and (max-width: 991px) {
        width: 100%;
        .d-flex {
          width: 100%;
          .form-group {
            width: 50%;
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }
        .multiselect {
          margin: 0;
        }
      }
    }
  }
  .topHead {
    margin-bottom: rem(18px);
    &__left {
      display: flex;
      align-items: center;
      .mainLinks {
        li:last-child {
          margin-right: 0.875rem;
        }
        a {
          padding-left: 12px;
          padding-right: 12px;
        }
      }
    }
    @media screen and (max-width: 1350px) {
      flex-direction: column;
      align-items: start;
      &__left {
        justify-content: space-between;
        width: 100%;
        margin-top: rem(20px);
        @media screen and (min-width: 992px) {
          * {
            margin-bottom: 5px !important;
          }
        }
      }
    }
    @media screen and (max-width: 991px) {
      &__left {
        flex-direction: column;
        align-items: center;
        .mainLinks {
          flex-direction: row;
          flex-wrap: nowrap;
          width: 100%;
          margin-bottom: 10px;
          li {
            a {
              background: #fff;
              height: auto;
              padding: 8px 0;
              border-radius: 8px;
              text-align: center;
              display: block;
              &:after {
                display: none;
              }
            }
            &:not(:last-child) {
              margin-right: 10px;
            }
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }
  .d-flex {
    .form-group {
      &:not(:last-child) {
        margin-right: rem(20px);
      }
    }
  }
}
</style>
